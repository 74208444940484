import { Client, ClientInfo } from './client';
import { AccountInfo } from 'components/modules/AccountInfo/AccountInfo.props';
import { Address, ShippingPreference } from './miscellaneous';
import { Row } from './tables';
import { UserProfile } from './user';
import { Variant } from './variants';
import { Inventory, Item } from './item';

export enum OrderStatus {
  CANCELLED = 40,
  CREATED = 0,
  DAMAGED_AND_UNASSIGNED = 50,
  DONE = 100,
  ONGOING = 21,
  PROCESSED = 10,
  RETURNED = 30,
  SHIPPED = 20,
  BEING_CREATED = -1,
}

// externalRepairName: '',
// repairMethod: null,
// trackingId: '',
// repairNotes: '',

export type ReturnDetail = {
  damaged?: number;
  item: string;
  notes?: string;
  proofs: (File | string)[];
  returned?: number;
  variant: string;
} & {
  itemData?: Item;
  unassigned?: number;
  repairQty?: number;
  decommissionedQty?: number;
  quantity?: number;
  isDamagedEnabled?: boolean;
  externalRepairName?: string;
  repairMethod?: number | null | undefined;
  trackingId?: string;
  repairNotes?: string;
};

export type ReturnDetailState = ReturnDetail & {
  itemName?: string;
  quantity?: number;
  isDamagedEnabled?: boolean;
};

export type OrderItemDetail<Tvariant = string, Titem = string> = {
  item: Titem;
  price: number;
  quantity: number;
  variant: Tvariant;
  image: File & { type: 'image/*' };
  inventory: Inventory;
};

export type Size = {
  height: number | null;
  width: number | null;
  depth: number | null;
  unit: string | null;
};
export type Package = {
  size: Size;
  weight: number | null;
};

export type Author = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type ShipOrder = {
  _id: string;
  orderNumber: string;
  accountInfo: AccountInfo;
  accountNumber: number;
  address: Address;
  arrivalDate: Date;
  author: Author;
  booth: string;
  client: Client;
  clientInfo: ClientInfo;
  customer: string;
  event: string;
  isClientBillToAccount: boolean;
  notes: string;
  orderDetail: OrderItemDetail[];
  orderStatus: number;
  package: Package;
  pickupDate: Date;
  prepByDate: Date;
  projectManager: UserProfile;
  isReturnable: boolean;
  isArchived: boolean;
  isDeleted: boolean;
  shipDate: Date;
  shippingPreference: string;
  status: number;
  warehouse: string;
  returnDetail: ReturnDetail[];
  createdAt: Date;
  updatedAt: Date;
  returnDate: Date;
  advanceWarehouseDate: {
    endDate: string | null;
    startDate: string | null;
  };
  recipient: {
    name: string;
    email: string;
    phone: string;
  };
};

export type RowShipOrder = Row & ShipOrder;

export type ShipOrderExtended = ShipOrder & {
  orderDetail: OrderItemDetail<Variant, Item>[];
  transactionNumber?: string;
  contact?: {
    contactName: string;
    phoneNumber: string;
  };
};

export type GeneralInfo = {
  shipOrderDetails: ShipOrderExtended;
};

export type AccountInfoUpdatePayload = {
  accountInfo: AccountInfo;
};

export type OrderPayload = {
  accountInfo: AccountInfo;
  accountNumber: number | null;
  address: Omit<Address, '_id'>;
  advanceWarehouseDate: {
    endDate: string | null;
    startDate: string | null;
  } | null;
  orderStatus: number;
  arrivalDate: string | null;
  booth: string;
  client: string;
  event: string;
  isClientBillToAccount: boolean;
  isReturnable: boolean;
  notes: string;
  orderDetail: OrderItemDetail[];
  package: {
    size: Size;
    weight: number | null;
  };
  pickupDate: string | null;
  prepByDate?: string | null;
  projectManager: string;
  shipDate: string | null;
  shippingPreference: string;
  warehouse: string;
  returnDate: string | null;
  rangeDate?: string[];
  clientInfo?: ClientInfo; // this was added to handle daterange picker for advanceWarehouseDate
  recipient: {
    name: string;
    email: string;
    phone: string;
  };
  contact?: {
    contactName: string;
    phoneNumber: string;
  };
};

// Define the interface for the entire data structure
export type ReveiwReturnPackagePayload = {
  returnDetail: ReturnDetail[];
  returnDate: string;
  action: string;
};
