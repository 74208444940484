import React, { FC, useState } from 'react';

import { OrderFormProps } from './OrderForm.props';

import OrderFormView from './OrderForm.view';
import { Formik, FormikErrors } from 'formik';
import Toast from 'helperClasses/toastHelper';
import { OrderPayload } from 'types/shipOrders';
import {
  ORDER_FORM_LOCAL_STORAGE_KEY,
  accountInfoValidations,
  generateInfoValidations,
  orderDetailValidations,
  warehouseValidationWithPackage,
} from 'utils/shipOrder';
import { useOrderLocalStorageState } from 'hooks/useOrderLocalStorageState';

const OrderFormContainer: FC<OrderFormProps> = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(
    props.defaultActiveTabIndex ?? 0,
  );
  const [validationStatus, updateValidationStatus] = useState([
    false,
    false,
    false,
  ]);

  const [validationCount, setValidationCount] = useState([0, 0, 0]);
  const handleTabClick = (
    index: number,
    currentTabValidationStatus: boolean,
  ) => {
    if (index > activeTabIndex) {
      if (activeTabIndex !== index && !currentTabValidationStatus) {
        Toast.error(
          'Please fill all the required fields, in the previous step',
        );
        return;
      }
    }
    setActiveTabIndex(index);
  };
  /**
   * Handles the change of tabs with errors and updates the validation status accordingly.
   *
   * @param {number} index - The index of the tab being changed.
   * @param {FormikErrors<OrderPayload>} errors - The errors associated with the form data.
   */
  const handleErrorOnChangeTab = (
    index: number,
    errors: FormikErrors<OrderPayload>,
  ) => {
    const hasErrors = Object.keys(errors).length > 0;

    //Might need in future to show status on the tabs
    // setValidationCount((prev) => {
    //   const updatedCounts = [...prev];
    //   updatedCounts[activeTabIndex] = hasErrors
    //     ? Object.keys(errors).length
    //     : 0;
    //   return updatedCounts;
    // });

    updateValidationStatus((prev) => {
      const updatedStatus = [...prev];
      updatedStatus[activeTabIndex] = !hasErrors;
      return updatedStatus;
    });

    handleTabClick(index, !hasErrors);
  };

  const validationsSchemas = [
    generateInfoValidations,
    orderDetailValidations,
    accountInfoValidations,
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      onSubmit={async (values) => {
        if (activeTabIndex < 2) {
          setActiveTabIndex((prev) => prev + 1);
          return;
        }
        props.onSubmit(values as OrderPayload);
      }}
      validationSchema={validationsSchemas[activeTabIndex]}>
      {({ validateForm, values, errors }) => {
        return (
          <>
            <OrderFormView
              title={props.title}
              initialValues={props.initialValues as OrderPayload}
              isEditable={props.isEditable}
              onSubmit={props.onSubmit}
              mode={props.mode}
              loading={props.loading}
              disabledTabIndex={props.disabledTabIndex}
              handleTabClick={handleTabClick}
              activeTabIndex={activeTabIndex}
              handleErrorOnChangeTab={handleErrorOnChangeTab}
              validationCount={validationCount}
              validateForm={validateForm}
              saveForm={props.handleUpdateForm as (e: unknown) => void}
              values={values}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default OrderFormContainer;
